import axios from "axios";
import store from "./../store";
import { userLogout } from "../actions";

const state = store.getState();
const authToken = state.currentUser.Data.access_token;

const instance = axios.create({

    //baseURL: 'http://localhost:52551/',
    //baseURL: "https://srtip.aisworx.services/",
    baseURL: "https://srtip.worx.ws/",
    //baseURL: "https://api.srtip.ae/",
	
	
	headers: {
		Authorization: `${authToken ? "Bearer " + authToken : ""}`,
	},
});

instance.interceptors.response.use(
	function (response) {
		// Any status code that lie within the range of 2xx cause this function to trigger
		// Do something with response data
		return response;
	},
	function (error) {
		// Any status codes that falls outside the range of 2xx cause this function to trigger
		// Do something with response error

		if (
			error.response.status === 401 ||
			error.response.statusText === "Unauthorized"
		) {
			store.dispatch(userLogout());
		}

		return Promise.reject(error);
	}
);

export default instance;
